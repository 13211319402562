/**
 * 基础路由
 * @type { *[] }
 */
export const constantRouterMap = [
  {
    path: '/',
    component: () => import('@/views/layouts/index'),
    redirect: '/home',
    meta: {
      title: '首页',
      keepAlive: true,
    },
    children:[
      {
        path: '/home',
        name: 'Home',
        component: () => import('@/views/home/index'),
        meta: { title: '浅网', keepAlive: true, hasFooter: true, location: 2 }
      },
      {
        path: '/trade/AnHeiYouDetail',
        name: 'TradeAnHeiYouDetail',
        component: () => import('@/views/trade/AnHeiYouDetail/index'),
        meta: { title: '暗黑游详情'   }
      },
      {
        path: '/trade/typeDteail',
        name: 'TradeTypeDteail',
        component: () => import('@/views/trade/typeDteail/index'),
        meta: { title: '暗黑游分类详情',   }
      },
      {
        path: '/trade/DiWangTiaoJiaoDetail',
        name: 'TradeDiWangTiaoJiaoDetail',
        component: () => import('@/views/trade/DiWangTiaoJiaoDetail/index'),
        meta: { title: '帝王调教详情'  }
      },
      {
        path: '/trade/postTransaction',
        name: 'TradePostTransaction',
        component: () => import('@/views/trade/postTransaction/index'),
        meta: { title: '默认发布页', }
      },
      {
        path: '/trade/postTransaction/jiaoYiPost',
        name: 'TradePostTransactionJiaoYiPost',
        component: () => import('@/views/trade/postTransaction/jiaoYiPost'),
        meta: { title: '交易页发布', }
      },
      {
        path: '/trade/myPost',
        name: 'TradePostmyPost',
        component: () => import('@/views/trade/myPost'),
        meta: { title: '我的发布', }
      },
      {
        path: '/trade',
        name: 'Trade',
        component: () => import('@/views/trade/index'),
        meta: { title: '暗网交易', keepAlive: true, hasFooter: true, location: 2 }
      },
      {
        path: '/post',
        name: 'Post',
        component: () => import('@/views/post/index'),
        meta: { title: '社区', keepAlive: true, hasFooter: true, location: 2 }
      },
      {
        path: '/deepWeb',
        name: 'DeepWeb',
        component: () => import('@/views/deepWeb/index'),
        meta: { title: '深网', keepAlive: true, hasFooter: true, location: 2 }
      },
      {
        path: '/mine/adds',
        name: 'mineAdds',
        component: () => import('@/views/mine/adds/index'),
        meta: { title: '关注列表',  location: 2 }
      },
      {
        path: '/mine',
        name: 'Mine',
        component: () => import('@/views/mine/index'),
        meta: { title: '个人中心', hasFooter: true,noHasHeader:true  }
      },
      {
        path: '/mine/setting',
        name: 'MineSetting',
        component: () => import('@/views/mine/setting/index'),
        meta: { title: '个人信息',  noHasHeader:true  }
      },
      {
        path: '/subpage/morePage',
        name: 'subpageMorePage',
        component: () => import('@/views/subpage/morePage/index'),
        meta: { title: '主题详情页',  noHasHeader:true  }
      },
      {
        path: '/subpage/labelPage',
        name: 'subpageLabelPage',
        component: () => import('@/views/subpage/labelPage/index'),
        meta: { title: '标签详情页',  noHasHeader:true  }
      },
      {
        path: '/subpage/search',
        name: 'subpageSearch',
        component: () => import('@/views/subpage/search/index'),
        meta: { title: '搜索页',  noHasHeader:true  }
      },
      {
        path: '/subpage/search/result/:value',
        name: 'subpageResult',
        component: () => import('@/views/subpage/search/result'),
        meta: { title: '搜索视频列表页',  noHasHeader:true  }
      },
      {
        path: '/subpage/tagType',
        name: 'subpageTagType',
        component: () => import('@/views/subpage/tagType/index'),
        meta: { title: '搜索片库',  noHasHeader:true  }
      },
      {
        path: '/subpage/discover',
        name: 'subpageDiscover',
        component: () => import('@/views/subpage/discover/index'),
        meta: { title: '发现列表',  noHasHeader:true  }
      },
      {
        path: '/subpage/play/longVideo/:id',
        name: 'subpagePlayLongVideo',
        component: () => import('@/views/subpage/play/longVideo/index'),
        meta: { title: '长视频播放页',  noHasHeader:true  }
      },
      {
        path: '/subpage/play/shortVideo/:id',
        name: 'subpagePlayShortVideo',
        component: () => import('@/views/subpage/play/shortVideo/index'),
        meta: { title: '短视频播放页',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/avatar',
        name: 'MineSettingAvatar',
        component: () => import('@/views/mine/setting/avatar'),
        meta: { title: '修改头像',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/bindPhone',
        name: 'MineSettingBindPhone',
        component: () => import('@/views/mine/setting/bindPhone'),
        meta: { title: '绑定手机',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/accoutCodeCamera',
        name: 'MineSettingAccoutCodeCamerae',
        component: () => import('@/views/mine/setting/accoutCodeCamera'),
        meta: { title: '凭证找回账号',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/accoutCode',
        name: 'MineSettingAccoutCode',
        component: () => import('@/views/mine/setting/accoutCode'),
        meta: { title: '账号凭证',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/phone',
        name: 'MineSettingPhone',
        component: () => import('@/views/mine/setting/phone'),
        meta: { title: '手机找回账号',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/accout',
        name: 'MineSettingAccout',
        component: () => import('@/views/mine/setting/accout'),
        meta: { title: '找回账号',  noHasHeader:true  }
      },
      {
        path: '/mine/setting/kf',
        name: 'MineSettingKf',
        component: () => import('@/views/mine/setting/kf'),
        meta: { title: '在线客服',  noHasHeader:true  }
      },
      {
        path: '/mine/login',
        name: 'MineLogin',
        component: () => import('@/views/mine/login/index'),
        meta: { title: '登陆',  noHasHeader:true  }
      },
      {
        path: '/mine/myGroup',
        name: 'MineMyGroup',
        component: () => import('@/views/mine/myGroup/index'),
        meta: { title: '官方群组',  noHasHeader:true  }
      },
      {
        path: '/mine/recruitment',
        name: 'MineRecruitment',
        component: () => import('@/views/mine/recruitment/index'),
        meta: { title: '官方招募',  noHasHeader:true  }
      },
      
      {
        path: '/mine/register',
        name: 'MineRegister',
        component: () => import('@/views/mine/register/index'),
        meta: { title: '注册',  noHasHeader:true  }
      },
      {
        path: '/mine/vip',
        name: 'MineVip',
        component: () => import('@/views/mine/vip/index'),
        meta: { title: '会员中心',  noHasHeader:true  }
      },
      {
        path: '/mine/vip/code',
        name: 'MineVipCode',
        component: () => import('@/views/mine/vip/code'),
        meta: { title: '会员兑换',  noHasHeader:true  }
      },
      {
        path: '/mine/transList',
        name: 'MineTransList',
        component: () => import('@/views/mine/transList/index'), 
        meta: { title: '流水记录',  noHasHeader:true  }
      },
      {
        path: '/mine/recharge',
        name: 'MineRecharge',
        component: () => import('@/views/mine/recharge/index'), 
        meta: { title: '充值记录',  noHasHeader:true  }
      },
      {
        path: '/mine/msg',
        name: 'MineMsgs',
        component: () => import('@/views/mine/msgs/index'),
        meta: { title: '消息中心',  noHasHeader:true  }
      },
      {
        path: '/mine/myWallet',
        name: 'MineMyWallet',
        component: () => import('@/views/mine/myWallet/index'),
        meta: { title: '我的钱包',  noHasHeader:true  }
      },
      {
        path: '/mine/cooperateApp',
        name: 'MineCooperateApp',
        component: () => import('@/views/mine/cooperateApp/index'),
        meta: { title: '应用中心',  noHasHeader:true  }
      },
      {
        path: '/mine/redemptionCode',
        name: 'MineRedemptionCode',
        component: () => import('@/views/mine/redemptionCode/index'),
        meta: { title: '兑换中心',  noHasHeader:true  }
      },
      {
        path: '/mine/myCollect',
        name: 'MineMyCollect',
        component: () => import('@/views/mine/myCollect/index'),
        meta: { title: '我的收藏',  noHasHeader:true  }
      },
      {
        path: '/mine/myHistory',
        name: 'MineMyHistory',
        component: () => import('@/views/mine/myHistory/index'),
        meta: { title: '浏览记录',  noHasHeader:true  }
      },
  
      {
        path: '/mine/myOrder',
        name: 'MineMyOrder',
        component: () => import('@/views/mine/myOrder/index'),
        meta: { title: '我的订单',  noHasHeader:true  }
      },
      {
        path: '/community/detail/:id',
        name: 'CommunityDetail',
        component: () => import('@/views/community/detail'),
        meta: { title: '帖子详情', noHasHeader: true }
      },{
        path: '/mine/myShouyi',
        name: 'MineMyShouyi',
        component: () => import('@/views/mine/myShouyi/index'),
        meta: { title: '推广福利',  noHasHeader:true  }
      },
      {
        path: '/mine/myShouyi/withdraw',
        name: 'MineMyShouyiWithdraw',
        component: () => import('@/views/mine/myShouyi/withdraw'),
        meta: { title: '提现',  noHasHeader:true  }
      },
      {
        path: '/mine/myShouyi/income',
        name: 'MineMyShouyiIncome',
        component: () => import('@/views/mine/myShouyi/income'),
        meta: { title: '收益明细',  noHasHeader:true  }
      },
      {
        path: '/mine/myWithdraw',
        name: 'MineMyWithdraw',
        component: () => import('@/views/mine/myWithdraw/index'),
        meta: { title: '提现记录',  noHasHeader:true  }
      },
      {
        path: '/mine/myShouyi/promotion',
        name: 'MineMyShouyiPromotion',
        component: () => import('@/views/mine/myShouyi/promotion'),
        meta: { title: '推广数据',  noHasHeader:true  }
      },
      {
        path: '/mine/myBack',
        name: 'MineMyBack',
        component: () => import('@/views/mine/myBack/index'),
        meta: { title: '意见反馈',  noHasHeader:true  }
      },
      {
        path: '/mine/myQuestion',
        name: 'MineMyQuestionk',
        component: () => import('@/views/mine/myQuestion/index'),
        meta: { title: '常见问题',  noHasHeader:true  }
      },
      {
        path: '/community/public',
        name: 'CommunityPublic',
        component: () => import('@/views/community/public'),
        meta: { title: '帖子发布',  noHasHeader:true ,keepAlive:true }
      },
      {
        path: '/community/typeList',
        name: 'CommunityTypeList',
        component: () => import('@/views/community/typeList'),
        meta: { title: '选择标签',  noHasHeader:true  }
      },
      {
        path: '/community/tagDteail',
        name: 'CommunityTagDteail',
        component: () => import('@/views/community/tagDteail'),
        meta: { title: '标签详情',   }
      },
      {
        path: '/mine/myInfo',
        name: 'myInfo',
        component: () => import('@/views/mine/myInfo'),
        meta: { title: '用户列表', keepAlive:true  }
      },
      {
        path: '/ai/facePhoto',
        name: 'facePhoto',
        component: () => import('@/views/ai/facePhoto/facePhoto'),
        meta: { title: 'AI换脸（图片）',   }
      },
      { 
        path: '/ai/generate',
        name: 'generate',
        component: () => import('@/views/ai/generate'),
        meta: { title: 'AI生成 ',  noHasHeader:true  }
      },
      { 
        path: '/ai/myGenerate',
        name: 'myGenerate',
        component: () => import('@/views/ai/myGenerate'),
        meta: { title: '我的AI生成 ',    }
      },
      {
        path: '/ai/changeFace',
        name: 'changeFace',
        component: () => import('@/views/ai/facePhoto/changeFace'),
        meta: { title: 'AI换脸',   }
      },
      {
        path: '/ai/uploadFace',
        name: 'uploadFace',
        component: () => import('@/views/ai/facePhoto/uploadFace'),
        meta: { title: 'AI脱衣',   }
      },
      {
        path: '/mine/sigin',
        name: 'sigin',
        component: () => import('@/views/mine/sigin/index'),
        meta: { title: '签到', }
      },
      {
        path: '/mine/sign-in/redeemhistory',
        name: 'mineRedeemhistory',
        component: () => import('@/views/mine/sigin/redeemhistory'),
        meta: { title: '兑换规则', }
      },
      {
        path: '/mine/sign-in/rule',
        name: 'mineRule',
        component: () => import('@/views/mine/sigin/rule'),
        meta: { title: '兑换记录', }
      },
      {
        path: '/mine/luckyDraw',
        name: 'mineLuckyDraw',
        component: () => import('@/views/mine/luckyDraw/index'),
        meta: { title: '幸运抽奖', }
      },
    ]
  }
]
