/*
 * @Author:
 * @Mail:
 * @Date: 2023-06-20 11:16:20
 * @LastEditTime: 2023-07-04 19:50:14
 * @LastEditors: Please set LastEditors
 * @FilePath: /web_base/component_base/component.js
 */

import Vue from 'vue'
console.log('公共库将在N个版本后，废弃这种引用方式')
//'替换成index.js在项目里按需注册'
Vue.component('EFootBar', () => import('./EFootBar'))
Vue.component('EBackTop', () => import('./EBackTop'))
Vue.component('EDecryptImg', () => import('./EDecryptImg'))
Vue.component('EDecryptImgNolazy', () => import('./EDecryptImgNolazy'))
Vue.component('EADSwiper', () => import('./EADSwiper'))
Vue.component('EGenerateImg', () => import('./EGenerateImg'))
Vue.component('ETab', () => import('./ETab'))
Vue.component('ERefreshLoad', () => import('./ERefreshLoad'))
Vue.component('EJumpLinkPage', () => import('./EJumpLinkPage'))
// Vue.component('EFootBar', () => import('./EFootBar'))

Vue.component('EAddPwa', () => import('./EAddPwa'))
Vue.component('ERefreshLoadV3', () => import('./ERefreshLoadV3'))
Vue.component('ERefreshingPage', () => import('./ERefreshingPage'))
Vue.component('ETabV2', () => import('./ETabV2'))
Vue.component('ETabBar', () => import('./ETabBar'))
