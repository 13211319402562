const state = {
  // 播放数据
  list: [],
  historyShortVideoIndex: 0,// 当前短视频播放的列表的索引
  begVideo:{},
  showAppList:true, //应用列表弹窗开关
}
const getters = {
  getShortVideoList(state) {
    return state.list
  },
  getHistoryShortVideoIndex(state){
    return state.historyShortVideoIndex
  },
  getBegVideo(state){
    return state.begVideo
  }
}
const mutations = {
  SET_VIDEOLIST(state, list) {
    state.list = list
  },
  SET_BEGVIDEO(state, obj) {
    console.log(obj,'obj')
    state.begVideo = obj
  },
  SET_SHORT_VIDEO_INDEX(state, index) {
    state.historyShortVideoIndex = index
  },
  SET_showAppList(state,showAppList){
    state.showAppList = showAppList
  }
}
export default {
  state,
  mutations,
  getters
}
