// 图片存储 表名  对应需要操作的config key
export const IMAGE_DB_KEY = 'image_db'
// 当前已经存储的数量是否已经达到上限 并开启循环写入
export const SAVE_COUNT_MAX_KEY = 'save_count_max'
// 默认开启图片存储，如果开启后性能有影响调用关闭 IMAGE_DB_CONFIG[START_IMAGE_DB_KEY] = false
export const START_IMAGE_DB_KEY = 'start_image_db'
//  当前超时的次数
export const TIME_OUT_NUMBER_KEY = 'time_out_number'

export const IMAGE_DB_CONFIG = {
  // 图片存储DB 名称
  db_name: 'imageDatabase',
  // 图片存储 store 名称
  store_name: 'imgList',
  // 图片存储 版本号码
  db_version: 1,
  // 图片存储 表名
  table_name: 'imgSrc',
  // 图片存储 键值
  table_key: 'src',
  // 图片存储 操作方式
  db_option: 'readwrite',
  // 初始化后的ImageDB
  image_db: null,
  // 图片最大存储上限 超出后溢出存储
  max_save_number: 2000,
  // 默认开启图片存储，如果开启后性能有影响调用关闭 IMAGE_DB_CONFIG[start_image_db] = false
  start_image_db: true,
  // 当前已经存储的数量是否已经达到上限 并开启循环写入
  save_count_max: false,
  // 请求DB超过最大耗时时间  毫秒
  max_time_consuming: 10000,
  // 超时超过最大次数 自动切换为 网络请求显示模式
  time_out_max_number: 30,
  // 当前超时的次数 IMAGE_DB_CONFIG[TIME_OUT_NUMBER_KEY] = 1
  time_out_number: 0
}

export const HTTP_DECRYPT_CONFIG = {
  // 请求时 Header 加密验签 中计算的 key 值
  http_request_key: 'kaFtkDJRcchRMTI9',
  // 解密响应结果是 解密密文 的 key 值
  http_response_key: 'vEukA&w15z4VAD3kAY#fkL#rBnU!WDhN',
  // 图片解密 的 key 值
  image_decrypt_key: '2019ysapp7527',
  // 是否需要开启 加密 请求
  start_encrypt_http: true,
  // 是否需要开启 m3u8 请求加密
  start_encrypt_m3u8: true
}
