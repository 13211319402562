
(function (doc, win) {
  var docEl = doc.documentElement;
  function IsPC() {
    var userAgentInfo = navigator.userAgent;
    var Agents = ["Android", "iPhone","SymbianOS", "Windows Phone","iPad", "iPod"];
    var flag = true;
    for (var v = 0; v<Agents.length; v++) {
      if (userAgentInfo.indexOf(Agents[v])>0) {
      flag = false;
      break;
      }
    }
    return flag;
  }
  docEl.style.display = 'none';
  //h5
  resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize',
    recalc = function () {
      var clientWidth = docEl.clientWidth;
      if (!clientWidth) return;
      if(IsPC()) return docEl.style.fontSize = '50px', docEl.style.display = 'block';
      if (clientWidth >= 750) {
        docEl.style.fontSize = '100px';
      } else {
        docEl.style.fontSize = 100 * (clientWidth / 750) + 'px';
      }
      docEl.style.display = 'block';
    };

  if (!doc.addEventListener) return;
  win.addEventListener(resizeEvt, recalc, false);
  doc.addEventListener('DOMContentLoaded', recalc, false);
})(document, window);
