// 判断是否加解密，如果字节前几位对应上，则不需要解密
const encryptMagicNumber = new Uint8Array([
  0x88, 0xa8, 0x30, 0xcb, 0x10, 0x76
])
// 解密密钥
const _decryptKey = Buffer.from('2019ysapp7527') // 加密key

const _encryptedLen = 100 // 加密图片的数据长度

// / 加密密钥(????，不解就去找游总解答)
const ENCRYPT_KEY = 0xa3
// 三种图片 type类型,根据前几子节可以判断出类型，这是固定的。如需要增加其他类型则自己上网找固定对应的字节。
const _featuresList = [
  new Uint8Array([0xff, 0xd8, 0xff]), // jpg,jpeg
  new Uint8Array([0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]), // png
  new Uint8Array([0x47, 0x49, 0x46]) // gif
]

/**
* 解密入口
*/
export function decryptImage(imgBytes) {
  // 判断是否为空，如果传入的字节为空则不处理
  if (imgBytes === null || imgBytes.length === 0) {
    return
  }
  // 增加一个变量来判断是否存在
  let isAll = false
  // 循环魔法密钥，确定这是需要解密的图片是全部长度，而不是前100字节
  for (let i = 0; i < encryptMagicNumber.length; i++) {
    if (encryptMagicNumber[i] !== imgBytes[i]) {
      continue
    }
    isAll = true
  }
  if (isAll) {
    imgBytes = xorBaseAllLength(imgBytes)
  } else {
    // 是需要解密的

    if (isEncryptedImage(imgBytes)) {
      // 解密结果
      imgBytes = xorBaseLength(imgBytes, _decryptKey, _encryptedLen)
    }
  }
  return imgBytes
}

/**
* 返回一个判断值，true则代表 是png gif jpg三种图片中的一种，则需要解密
*/
function isEncryptedImage(imgBytes) {
  let isDecrypted = false
  const _featuresLen = _featuresList.length
  for (let i = 0; i < _featuresLen; i++) {
    // 每次进入则初始化变量的值为假
    isDecrypted = false
    for (let j = 0; j < _featuresList[i].length; j++) {
      if (_featuresList[i][j] !== imgBytes[j]) {
        isDecrypted = true
        break
      }
    }
    if (isDecrypted) {
      continue
    } else {
      break
    }
  }
  return isDecrypted
}
// 解密算法
function xorBaseLength(src, key, length) {
  const srcLen = src.length
  const keyLen = key.length
  if (length > srcLen || length <= 0) {
    length = srcLen
  }
  for (let i = 0; i < length; i += keyLen) {
    for (let j = 0; j < keyLen && i + j < length; j++) {
      src[i + j] ^= key[j]
    }
  }
  return src
}

// 全部长度解密，解密算法
function xorBaseAllLength(src) {
  var index = -1
  // 这里map数组内 所有的字节做位异或运数，返回的是解密后的数组
  var dest = src.map((it) => {
    index++
    if (
      index < encryptMagicNumber.length &&
          it === encryptMagicNumber[index]
    ) {
      return null
    }
    return it ^ ENCRYPT_KEY
  })
  return dest
}

