//   /// 未定义位置,
//   undef,
//   ///表示启动页广告 position 1
//   splashAD,
//   /// av首页广告 2
//   avAD,
//   /// 小视频首页 3
//   short_videoAD,
//   /// 动漫首页 4
//   cartongAD,
//   /// 播放页广告 5
//   playAD,
//   /// 约炮广告 6
//   datingAD,
//   ///我的广告 7
//   mineAD,
//   ///社区广告 8
//   commityAD,
//   /// 弹窗广告 9
//   popAD,

const state = {
  config: {
  },
  //
  // 渠道参数
  channel: null,
  ver: '1.0.2',
  noticeStatus: true,
  // 启动页
  hasSplashStatus: true,
  // 弹出广告
  hasSystemADStatus: false,
  announcementPop: false, //首页公告开关
  activityPop: false, // 首页活动开关
  splashAD: [], // 1启动页广告
  avAD: [], // 2
  short_videoAD: [], // 3 搜索页广告
  cartongAD: [], // 4
  playAD: [], // 4
  datingAD: [], // 9
  mineAD: [], //5
  commityAD: [], // 8
  popAD: [],// 6,
  postCategory: [],// 10
  lotteryAd: [],//11
  postSection: [],// 
  hasNewMsg: true, // 是否有新消息
}

const getters = {
  hasSplashStatus(state) {
    return state.hasSplashStatus
  },
  hasNewMsg(state) {
    return state.hasNewMsg
  },
  announcementPop(state) {
    return state.announcementPop
  },
  activityPop(state) {
    return state.activityPop
  },
  noticeStatus(state) {
    return state.noticeStatus
  },
  getChannel(state) {
    return state.channel
  },
  config(state) {
    if (state.config) {
      return state.config
    } else {
      return '{}'
    }
  },

  // 落地页域名
  ldyCdn(state) {
    const { domain } = state.config;
    let ldyCdn = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'LAND') {
          if (i.urls.length > 0) {
            ldyCdn = i.urls[0]
          }
        } else {
          return false
        }
      })
    }
    return ldyCdn
  },
  // api域名
  apiUrl(state) {
    const { domain } = state.config;
    let apiUrl = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'API') {
          if (i.urls.length > 0) {
            apiUrl = i.urls[2] || i.urls[1] || i.urls[2] || i.urls[3]
          }
        } else {
          return false
        }
      })
    }
    return apiUrl
  },
  // tg联系方式
  tgUrl(state) {
    const { domain } = state.config;
    let tgUrl = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'TELEGRAM') {
          if (i.urls.length > 0) {
            tgUrl = i.urls[0] || i.urls[1] || i.urls[2] || i.urls[3]
          }
        } else {
          return false
        }
      })
    }
    return tgUrl
  },
  // 土豆联系方式
  potatoUrl(state) {
    const { domain } = state.config;
    let potatoUrl = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'POTATO') {
          if (i.urls.length > 0) {
            potatoUrl = i.urls[0] || i.urls[1] || i.urls[2] || i.urls[3]
          }
        } else {
          return false
        }
      })
    }
    return potatoUrl
  },
  // 活动公告
  announcement(state) {
    return state.config.announcement
  },
  // 公告
  runningLight(state) {
    return state.config.runningLight
  },
  // 下载app 图标
  dlAppInfo(state) {
    return state.config.dlAppInfo
  },

  // 不用解密的cdn
  notEncrypCDN(state) {
    const { domain } = state.config
    let cdn = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'NOENCRIMAGE') {
          if (i.urls.length > 0) {
            cdn = i.urls[0]
          }
        } else {
          return false
        }
      })
    }
    return cdn
  },
  // 加密的cdn域名
  cdn(state) {
    const { domain } = state.config
    let cdn = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'IMAGE') {
          if (i.urls.length > 0) {
            cdn = i.urls[0]
          }
        } else {
          return false
        }
      })
    }
    return cdn
  },
  aiImg(state) {
    const { domain } = state.config
    let cdn = ''
    if (domain) {
      domain.forEach((i) => {
        if (i.type === 'AIIMG') {
          if (i.urls.length > 0) {
            cdn = i.urls[0]
          }
        } else {
          return false
        }
      })
    }
    return cdn
  },
  shareUrl(state) {
    const { domain } = state.config;
    let url = "";
    if (domain) {
      domain.forEach((i) => {
        if (i.type === "H5SHAREURL") {
          if (i.urls.length > 0) {
            url = i.urls[0];
          }
        } else {
          return false;
        }
      });
    }
    return url
  },

  // 首页
  homecategoryV2(state) {
    console.log(state.config, "state.config")
    const { homecategoryV2 } = state.config
    return homecategoryV2
  },
  // 首页
  homecategoryTag(state) {
    const { avcategory } = state.config
    console.log(state.config, "state.config")
    return avcategory.filter(item => item.showType === 1)
  },
  // up首页tab列表
  homeUpCategoryTab(state) {
    const { upCategory } = state.config
    return upCategory
  },
  // 首页av分类
  avcategoryv2(state) {
    console.log(state.config)
    const { avcategoryv2 } = state.config
    return avcategoryv2
  },

  avcategory(state) {
    const { avcategory } = state.config
    return avcategory
  },
  // 首页动漫分类
  cartooncategoryV2(state) {
    const { cartooncategoryV2 } = state.config
    return cartooncategoryV2
  },
  // 首页国产分类
  mdcategoryV2(state) {
    const { mdcategoryV2 } = state.config
    return mdcategoryV2
  },
  // 首页原创分类
  createcategoryV2(state) {
    const { createcategoryV2 } = state.config
    return createcategoryV2
  },
  // 首页小视频分类
  shortcategoryV2(state) {
    const { shortcategoryV2 } = state.config
    return shortcategoryV2
  },

  // 获取短视频列表
  mediaCategory(state) {
    const { mediaCategory } = state.config
    return mediaCategory
  },
  // 获取动漫标签
  cartoonCategory(state) {
    const { cartoonCategory } = state.config
    return cartoonCategory
  },
  // 获取漫画标签
  comicsCategory(state) {
    const { comicsCategory } = state.config
    return comicsCategory
  },
  // 获取首页帖子tab分类
  findCategory(state) {
    const { findCategory } = state.config
    return findCategory
  },
  // 获取首页帖子求片ID
  wantToId(state) {
    return null
  },
  // 获取视频播放类别
  payType(state) {
    const { payType } = state.config
    return payType
  },
  // 获取短视频标签
  shortTag(state) {
    const { shortTag } = state.config
    return shortTag
  },
  // 获取活动列表
  notificationInfo(state) {
    const { notificationInfo } = state.config
    return notificationInfo
  },
  // 获取启动首页活动
  splashAD(state) {
    const { advertise } = state.config;
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 1);
      return arr;
    }

    return [];
  },
  // 获取av首页活动
  avAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 2)
      return arr
    }
    return []
  },
  // 获取搜索页 广告
  short_videoAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 3)
      return arr
    }
    return []
  },
  // 获取动漫首页活动
  cartongAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 4)
      return arr
    }
    return []
  },
  // 获取长视频播放页首页活动
  playAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 4)
      return arr
    }
    return []
  },
  // 约炮
  datingAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 9)
      return arr
    }
    return []
  },
  // 获取个人中心首页活动
  mineAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 5)
      return arr
    }
    return []
  },

  // 获取社区首页活动
  commityAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 8)
      return arr
    }
    return []
  },
  // 获取发现页
  discoverAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 7)
      return arr
    }
    return []
  },
  // 获取弹窗首页活动
  popAD(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 6)
      return arr
    }
    return []
  },
  // 九宫格弹窗广告
  lotteryTopAd(state) {
    const { advertise } = state.config
    if (advertise && advertise.length > 0) {
      const arr = advertise.filter((i) => i.position === 10)
      return arr
    }
    return []
  },
  // 九宫格弹窗应用列表
  lotteryAd(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(1)) || []
  },
  // 娱乐列表
  getRecreationList(state) {
    return getters.iconAds(state).filter(item => item?.locIds?.includes(2)) || []
  },

  // 暗黑交易 帖子分类
  postCategory(state) {
    const { postCategory } = state.config
    if (postCategory && postCategory.length > 0) {
      return postCategory
    }
    return []
  },
  postSection(state) {
    const { postSection } = state.config
    if (postSection && postSection.length > 0) {
      return postSection
    }
    return []
  },
  getPostSectionByType: (state) => (type) => {
    const { postCategory, postSection } = state.config
    type = Number(type)
    if (type && postCategory && postCategory.length > 0) {
      const typeList = postCategory.filter(v => v.type == type)
      const section = []
      typeList.forEach(v => {
        postSection.forEach(item => {
          if (v.id == item.postCategoryId) {
            section.push(item)
          }
        })
      })
      return section
    }
    return []
  },
  iconAds(state) {
    return state.config?.statConfigApp?.list || []
  },
    //首页Icon ad
    iconHomeAds(state) {
      return getters.iconAds(state).filter(item => item?.locIds?.includes(3)) || []
    },
  //   社区顶部icon ad
    iconSqAds(state) {
      return getters.iconAds(state).filter(item => item?.locIds?.includes(4)) || []
    },
    //   我的icon ad
    iconMineAds(state) {
      return getters.iconAds(state).filter(item => item?.locIds?.includes(5)) || []
    },
     //   视频播放页 ad
     iconVideoPlayAds(state) {
      return getters.iconAds(state).filter(item => item?.locIds?.includes(6)) || []
    },

}

const mutations = {
  SET_PARAMS(state, obj) {
    const key = obj.key
    const newChannel = {}
    newChannel[key] = obj.data
    state.channel = {
      ...state.channel, ...newChannel
    }

  },
  SET_ANNOUNCEMENT_POP(state, status) {
    state.announcementPop = status
  },
  SET_ACTIVITY_POP(state, status) {
    state.activityPop = status
  },

  SET_CONFIG(state, obj) {
    state.config = obj
  },
  SET_NOTICE_STATUS(state, status) {
    state.noticeStatus = status
  },
  SET_SPLASH_STATUS(state, status) {
    state.hasSplashStatus = status
  },
  SET_SYSTEM_AD_STATUS(state, status) {
    state.hasSystemADStatus = status
  },
  setHasNewMsg(state, status) {
    state.hasNewMsg = status
  }
}
const actions = {
  setParams({ commit }, obj) {
    if (obj && obj.key) {
      commit('SET_PARAMS', obj)
    }
  },
  setConfig({ commit }, obj) {
    commit('SET_CONFIG', obj)
  },
  setHomAD({ commit }, arr) {
    commit('SET_HOMEAD', arr)
  },
  setNoticeStatus({ commit }, status) {
    commit('SET_NOTICE_STATUS', status)
  },

}

export default {
  state,
  mutations,
  actions,
  getters
}
